import {createStore} from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import auth from "./modules/auth.js"
import payslips from "./modules/payslips.js"
import dashboard from "./modules/dashboard.js"
import settings from "./modules/settings.js"

const store = createStore({
    state: {
        processing: false,
        snackbar: {
            status: false,
            text: '',
            color: ''
        },
        theme: 'light',
        locale: 'fr'
    },
    mutations: {
        set_processing(state, status) {
            state.processing = status
        },
        set_snackbar(state, snackbar) {
            state.snackbar = snackbar
        },
        init_snackbar(state) {
            state.snackbar = {
                status: false,
                text: '',
                color: ''
            }
        },
        switch_theme(state, theme) {
            state.theme = theme
        },
        switch_locale(state, locale) {
            state.locale = locale
        }
    },
    plugins: [
        createPersistedState({
            paths: ['auth.authenticated', 'auth.access_token', 'auth.user', 'theme', 'locale']
        })
    ],
    modules: {
        auth,
        payslips,
        dashboard,
        settings
    },
})

export default store
