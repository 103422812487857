import axios from 'axios'
import store from '@user-plugins/store/index.js'

const axiosInstanceUser = axios.create({
    baseURL: import.meta.env.VITE_APP_URL_API,
});

axiosInstanceUser.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response.data.message === "unauthenticated") {
            store.commit('auth/set_logout')
        }
        return Promise.reject(error);
    }
);

export default axiosInstanceUser;