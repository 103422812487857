import axiosInstanceUser from '@user-plugins/axios/index.js'

export default {
    namespaced: true,
    state: {
        user: {},
        passwords: {
            current_password: '',
            new_password: '',
            confirm_new_password: ''
        },
        errors: {},
    },
    mutations: {
        set_user(state, user) {
            state.user = user
        },
        set_errors(state, errors) {
            state.errors = errors
        },
    },
    actions: {

        async change_infos({rootState, state, commit}) {
            commit('set_processing', true, {root: true});
            return axiosInstanceUser.post(`/settings/infos`, state.user, {
                headers: {
                    Authorization: rootState.auth.access_token,
                },
            })
                .then(() => {
                    return {success: true, message: 'notif_modify_infos'};
                })
                .catch((error) => {
                    console.error('Erreur lors de la mise à jour du statut :', error);
                    if (error.response && error.response.status === 422) {
                        commit('set_errors', error.response.data.errors);
                    }
                    throw error;
                })
                .finally(() => {
                    commit('set_processing', false, {root: true});
                });
        },

        async change_password({rootState, state, commit}) {
            commit('set_processing', true, {root: true});
            return axiosInstanceUser.post(`/settings/password`, state.passwords, {
                headers: {
                    Authorization: rootState.auth.access_token,
                },
            })
                .then(() => {
                    return {success: true, message: 'notif_modify_passwords'};
                })
                .catch((error) => {
                    console.error('Erreur lors de la mise à jour du statut :', error);
                    if (error.response && error.response.status === 422) {
                        commit('set_errors', error.response.data.errors);
                    }
                    throw error;
                })
                .finally(() => {
                    commit('set_processing', false, {root: true});
                });
        },

    }
}
