import { createRouter, createWebHistory } from 'vue-router'
import store from '@user-plugins/store/index.js'

import routes from './routes.js'

const router = createRouter({
    history: createWebHistory(''),
    routes,
})

router.beforeEach((to, from, next) => {
    if (to.meta.middleware === "guest") {
        if (store.state.auth.authenticated) {
            next({ name: "dashboard" })
        }
        next()
    } else {
        if (store.state.auth.authenticated) {
            next()
        } else {
            next({ name: "login" })
        }
    }
})

export default router
