import {createI18n} from 'vue-i18n'
import {fr} from 'vuetify/locale'
import {en} from 'vuetify/locale'
import store from '@user-plugins/store/index.js'

const messages = {
    fr: {
        $vuetify: {
            ...fr,
        },
        '10': '10',
        '25': '25',
        '50': '50',
        '100': '100',
        "loading": "Loading...",
        "infiniteScroll": {
            "loadMore": "Load more"
        },
        "invalid_login": "Identifiant invalide",
        "active_users": "Utilisateurs actifs",
        "administrators": "Administrateurs",
        "home": "Accueil",
        "the_payslips": "Les bulletins de paie",
        "payslips": "Bulletins de paie",
        "balance_of_any_account": "Solde de tout compte",
        "loadings": "Chargements",
        "welcome": "Bienvenue",
        "users": "Utilisateurs",
        "logged_system": "Se connecte au système",
        "downloaded_file": "A téléchargé le fichier",
        "all": "Tous",
        "assignment": "Affectation",
        "affected": "Affecté",
        "unaffected": "Non affecté",
        "display": "Affichage",
        "poster": "Afficheur",
        "hidden": "Caché",
        "type": "Type",
        "payslip": "Bulletin de paie",
        "modify": "Modifier",
        "notification": "Notification",
        "notified": "Notifié",
        "not_notified": "Non notifié",
        "file_name": "Nom de fichier",
        "downloader": "Téléchargeur",
        "upload": "Télécharger",
        "period": "Période",
        "downloads": "Téléchargements",
        "assigned_to": "Assigné à",
        "visualization": "Visualisation",
        "search": "Recherche",
        "download": "Télécharger",
        "import": "Importer",
        "import_users": "Importer des utilisateurs",
        "import_payslips": "Importer des bulletins de paie",
        "select_files": "Sélectionner des fichiers",
        "please_select_the_files": "Veuillez sélectionner les fichiers au format (pdf)",
        "the_users": "Les utilisateurs",
        "cancel": "Annuler",
        "delete": "Supprimer",
        "situation": "Situation",
        "admin": "Admin",
        "employee": "Employé",
        "enabled": "Activé",
        "disabled": "Désactivé",
        "added": "Ajouter",
        "full_name": "Nom complet",
        "email": "E-mail",
        "username": "Nom d'utilisateur",
        "password": "Mot de passe",
        "sso": "SSO",
        "add_user": "Ajouter un utilisateur",
        "add_admin": "Ajouter un administrateur",
        "select_file": "Sélectionner un fichier",
        "please_choose_an_excel_file": "Veuillez choisir un fichier Excel (.xlsx) contenant un tableau avec les colonnes :",
        "the_administrators": "Les administrateurs",
        "edit_information": "Modifier les informations",
        "change_password": "Changer le mot de passe",
        "current_password": "Mot de passe actuel",
        "new_password": "Nouveau mot de passe",
        "confirm_the_new_password": "Confirmer le nouveau mot de passe",
        "save_change": "Sauvegarder les modifications",
        "settings": "Paramètres",
        "sign_out": "Se déconnecter",
        "notif_add_success": "Été ajouté avec succès.",
        "notif_edit_success": "Été modifié avec succès.",
        "notif_delete_success": "Été supprimé avec succès.",
        "notif_reset_password": "Le mot de passe a été réinitialisé.",
        "notif_toggle_status": "Puis changez le statut avec succès",
        "title_confirm_delete": "Confirmer la suppression",
        "message_confirm_delete": "Êtes-vous sûr que vous voulez supprimer",
        "edit_file": "Modifier le fichier",
        "login": "Se connecter",
        "remember_me": "Reste connecté",
        "message_2fa": "Nous avons envoyé un code de vérification à votre adresse e-mail",
        "check": "Vérifier",
        "message_didn_code": "Vous n'avez pas reçu le code ?",
        "resend": "Renvoyer",
        "Invalid_2fa_expired": "Le code 2FA est invalide ou a expiré. Veuillez générer un nouveau code et réessayer",
        "Invalid_2fa_code": "Code 2FA invalide. Veuillez saisir à nouveau votre code d'authentification à deux facteurs",
        "message_forgot_password": "Entrez votre email et nous vous enverrons des instructions pour réinitialiser votre mot de passe",
        "send_reset_link": "Envoyer le lien de réinitialisation",
        "back_to_login": "Retour à la page de login"
    },
    en: {
        $vuetify: {
            ...en,
        },
        '10': '10',
        '25': '25',
        '50': '50',
        '100': '100',
        "loading": "Loading...",
        "infiniteScroll": {
            "loadMore": "Load more"
        },
        "invalid_login": "Identifiant Invalide",
        "active_users": "Active Users",
        "administrators": "Administrators",
        "home": "Home",
        "the_payslips": "The Payslips",
        "payslips": "Payslips",
        "balance_of_any_account": "Balance Of Any Account",
        "loadings": "Loadings",
        "welcome": "Welcome",
        "users": "Users",
        "logged_system": "Connects To The System",
        "downloaded_file": "Downloaded The File",
        "all": "All",
        "assignment": "Assignment",
        "affected": "Affected",
        "unaffected": "Unaffected",
        "display": "Display",
        "poster": "Show",
        "hidden": "Hidden",
        "type": "Type",
        "payslip": "Payslip",
        "notification": "Notification",
        "notified": "Notified",
        "not_notified": "Not Notified",
        "file_name": "File Name",
        "downloader": "Downloader",
        "import": "Import",
        "import_users": "Import Users",
        "upload": "Upload",
        "period": "Period",
        "downloads": "Downloads",
        "assigned_to": "Assigned To",
        "visualization": "Visualization",
        "search": "Search",
        "download": "Download",
        "import_payslips": "Import Payslips",
        "select_files": "Select Files",
        "please_select_the_files": "Please Select The Files In (pdf)",
        "the_users": "The Users",
        "cancel": "Cancel",
        "delete": "Delete",
        "modify": "Modify",
        "situation": "Situation",
        "admin": "Admin",
        "employee": "Employee",
        "enabled": "Enabled",
        "disabled": "Disabled",
        "added": "Added",
        "full_name": "Full Name",
        "email": "E-mail",
        "username": "Username",
        "password": "Password",
        "sso": "SSO",
        "add_user": "Add User",
        "add_admin": "Add Administrator",
        "select_file": "Select File",
        "please_choose_an_excel_file": "Please Choose An Excel File (.xlsx) That Contains A Table With The Columns:",
        "the_administrators": "The Administrators",
        "edit_information": "Edit Information",
        "change_password": "Change Password",
        "current_password": "Current Password",
        "new_password": "New Password",
        "confirm_the_new_password": "Confirm The New Password",
        "save_change": "Save Change",
        "settings": "Settings",
        "sign_out": "Sign Out",
        "notif_add_success": "Been Added Successfully.",
        "notif_edit_success": "Been Modified Successfully.",
        "notif_delete_success": "Been Deleted Successfully.",
        "notif_reset_password": "Been Reset Password.",
        "notif_toggle_status": "Then Change The Status Successfully",
        "title_confirm_delete": "Confirm The Deletion",
        "message_confirm_delete": "Are You Sure You Want To Delete",
        "edit_file": "Edit File",
        "login": "Login",
        "remember_me": "Remember Me",
        "message_2fa": "We Have Sent A Verification Code To Your Email Address",
        "check": "Check",
        "message_didn_code": "Didn't Receive The Code?",
        "resend": "Resend",
        "Invalid_2fa_expired": "The 2FA Code Is Invalid Or Expired. Please Generate A New Code And Try Again",
        "Invalid_2fa_code": "Invalid 2FA Code. Please Re-enter Your Two-Factor Authentication Code",
        "message_forgot_password": "Enter Your Email And We'll Send You Instructions To Reset Your Password",
        "send_reset_link": "Send Reset Link",
        "back_to_login": "Back To Login"
    },
}

const i18n = createI18n({
    legacy: false,
    locale: store.state.locale,
    fallbackLocale: store.state.locale,
    messages,
})

export default i18n;
