import 'vuetify/styles'
import '@fortawesome/fontawesome-free/css/all.css'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import defaults from './defaults.js'
import theme from './theme.js'
import { aliases, fa } from 'vuetify/iconsets/fa'

import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n'
import { useI18n } from 'vue-i18n'
import i18n from '@user-plugins/i18n'

const vuetify = createVuetify({
    components,
    directives,
    defaults,
    theme,
    icons: {
        defaultSet: 'fa',
        aliases,
        sets: {
            fa,
        },
    },
    locale: {
        adapter: createVueI18nAdapter({ i18n: i18n, useI18n }),
    },
})

export default vuetify