export default {
    VAlert: {
        density: 'comfortable',
        VBtn: {
            color: undefined,
        },
    },
    VAvatar: {
        variant: 'flat',
    },
    VBadge: {
        color: 'primary',
    },
    VBtn: {
        color: 'primary',
    },
    VChip: {
        elevation: 0,
        density: 'comfortable',
    },
    VList: {
        VListItem: {
            color: 'primary',
        },
    },
    VPagination: {
        density: 'compact',
    },
    VTabs: {
        color: 'primary',
        VSlideGroup: {
            showArrows: true,
        },
    },
    VTooltip: {
        location: 'top',
    },
    VCheckboxBtn: {
        color: 'primary',
    },
    VCheckbox: {
        color: 'primary',
        density: 'comfortable',
        hideDetails: 'auto',
    },
    VRadioGroup: {
        color: 'primary',
        density: 'comfortable',
        hideDetails: 'auto',
    },
    VRadio: {
        density: 'comfortable',
        hideDetails: 'auto',
    },
    VSelect: {
        variant: 'outlined',
        color: 'primary',
        hideDetails: 'auto',
    },
    VRangeSlider: {
        thumbSize: 14,
        color: 'primary',
        density: 'comfortable',
        thumbLabel: true,
        hideDetails: 'auto',
    },
    VRating: {
        color: 'rgba(var(--v-theme-on-background),0.22)',
        activeColor: 'warning',
    },
    VProgressCircular: {
        color: 'primary',
    },
    VSlider: {
        thumbSize: 14,
        color: 'primary',
        hideDetails: 'auto',
    },
    VTextField: {
        variant: 'outlined',
        color: 'primary',
        hideDetails: 'auto',
        density: 'compact',
    },
    VAutocomplete: {
        variant: 'outlined',
        color: 'primary',
        hideDetails: 'auto',
        density: 'compact',
        VChip: {
            density: 'default',
        },
    },
    VCombobox: {
        variant: 'outlined',
        color: 'primary',
        hideDetails: 'auto',
        density: 'compact',
    },
    VFileInput: {
        variant: 'outlined',
        color: 'primary',
        hideDetails: 'auto',
        density: 'compact',
    },
    VTextarea: {
        variant: 'outlined',
        density: 'comfortable',
        color: 'primary',
        hideDetails: 'auto',
    },
    VSwitch: {
        color: 'primary',
        hideDetails: 'auto',
    },
    VIcon: {
        size: 'x-small'
    }
}
